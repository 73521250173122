@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .paused {
    animation-play-state: paused;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Avenir Next", sans-serif !important;
}

@keyframes investors1Ani {
  0% {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

@keyframes investors2Ani {
  0% {
    transform: translate(0, -0px);
  }

  65% {
    transform: translate(0, -15px);
  }

  100% {
    transform: translate(0, 0px);
  }
}

.investors1 {
  animation-name: investors1Ani;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.investors2 {
  animation-name: investors2Ani;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

button.b3 {
  background: linear-gradient(217.12deg, #FDD01F 2.48%, #FDD320 35.99%, #F8C240 47.49%, #F8861B 93.07%);
  position: relative;
  isolation: isolate;
  border-radius: 18px;
  scale: 1;
  transition: all 5.33s linear(0, 0.002, 0.01 0.9%, 0.038 1.8%, 0.156, 0.312 5.8%, 0.789 11.1%, 1.015 14.2%,
      1.096, 1.157, 1.199, 1.224 20.3%, 1.231, 1.231, 1.226, 1.214 24.6%,
      1.176 26.9%, 1.057 32.6%, 1.007 35.5%, 0.984, 0.968, 0.956, 0.949 42%,
      0.946 44.1%, 0.95 46.5%, 0.998 57.2%, 1.007, 1.011 63.3%, 1.012 68.3%,
      0.998 84%, 1);
}

button.b3:hover:not(:active),
button.b3.active {
  transition-duration: calc(1.33s * 0.5);
  scale: 1.1;
  box-shadow: 0 4px 8px -2px hsl(222deg 50% 20% / 50%), inset 0 0 0 transparent;
}

button.b3:active {
  scale: 1.1;
  transition-duration: calc(1.33s * 0.5);
}

button.b3:hover .shimmer2::before,
button.b3:hover .shimmer2::after,
button.b3.active .shimmer2::before,
button.b3.active .shimmer2::after {
  opacity: 1;
  animation: shine 1.2s ease-in 1 forwards;
}

@property --shimmer {
  syntax: "<angle>";
  inherits: false;
  initial-value: 33deg;
}

@keyframes shimmer {
  0% {
    --shimmer: 0deg;
  }

  100% {
    --shimmer: 360deg;
  }
}

.shimmer2 {
  position: absolute;
  inset: -40px;
  border-radius: inherit;
  mask-image: conic-gradient(from var(--shimmer, 0deg),
      transparent 0%,
      transparent 10%,
      black 36%,
      black 45%,
      transparent 50%,
      transparent 60%,
      black 85%,
      black 95%,
      transparent 100%);
  mask-size: cover;
  mix-blend-mode: plus-lighter;
  animation: shimmer 1s linear infinite both;
}

.shimmer2::before,
.shimmer2::after {
  transition: all 0.5s ease;
  opacity: 0;
  content: "";
  border-radius: inherit;
  position: absolute;
  mix-blend-mode: color;
  inset: 40px;
  pointer-events: none;
}

.shimmer2::before {
  box-shadow: 0 0 3px 2px hsl(222deg 20% 95%),
    0 0 7px 4px hsl(222deg 20% 80%),
    0 0 13px 4px hsl(222deg 50% 70%),
    0 0 25px 5px hsl(222deg 100% 70%);
  z-index: -1;
}

.shimmer2::after {
  box-shadow: inset 0 0 0 1px hsl(222deg 70% 95%),
    inset 0 0 2px 1px hsl(222deg 100% 80%),
    inset 0 0 5px 2px hsl(222deg 100% 70%);
  z-index: 2;
}


/* investor form */

.react-international-phone-input-container {
  height: 100%;
}

.react-international-phone-input-container .react-international-phone-country-selector-button {
  border-radius: 0px !important;
  margin-right: 20px !important;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: #000;
  opacity: 1;
  /* padding-block: 0px 15px !important; */
  /* box-shadow: rgba(230, 230, 230, 0.3) 0px 1px; */
  height: 100%;
  background: transparent !important;
}

.react-international-phone-input-container .react-international-phone-input {
  border: none !important;
  width: 100%;
  background: transparent !important;
  font-size: 14px !important;
  margin-top: 3px !important;
  color: #000 !important;
  height: 100% !important;
  margin-inline: auto;
}

.react-international-phone-flag-emoji {
  width: 30px !important;
  height: 30px !important;
  box-sizing: border-box;
  object-fit: cover;
}

.react-international-phone-country-selector-button__flag-emoji {
  margin: 0px 6px !important;
}

.react-international-phone-country-selector-dropdown {
  z-index: 50 !important;
  border-radius: 8px !important;
  border: 2px solid #504FFF !important;
}

.react-international-phone-country-selector-dropdown__list-item {
  height: 44px;
  max-width: 100%;
  width: auto !important;
  margin-inline: 8px;
  margin-block-end: 4px;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: rgba(102, 102, 102, 0.6) 0px 0px 0px 1px inset;
  color: #000000 !important;
  outline: 0px;
  padding-block: 4px;
  transition-duration: 0.1s;
  transition-property: color;
  transition-timing-function: ease-out;
  width: 100%;
  word-break: break-word;
  cursor: pointer;
  opacity: 1;
  padding: 0px 8px !important;
}

.react-international-phone-country-selector-dropdown__list-item-country-name {
  color: #000 !important;
  font-size: 14px !important;
  line-height: 28px !important;
  font-family: 'Avenir Next';
  font-weight: 600;
}

.react-international-phone-country-selector-dropdown__list-item-dial-code {
  color: #535353 !important;
  font-size: 14px !important;
  line-height: 28px !important;
  margin-left: 5px;
  font-weight: 500;
}

@media (max-width : 425px) {
  .react-international-phone-input-container .react-international-phone-input {
    font-size: 12px !important;
  }

  .react-international-phone-flag-emoji {
    width: 20px !important;
    height: 20px !important;
  }

  .react-international-phone-country-selector-dropdown {
    width: 250px !important;
    max-height: 200px !important;
  }

  .react-international-phone-country-selector-dropdown__list-item-dial-code {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-left: 0px !important;
  }

  .react-international-phone-country-selector-dropdown__list-item-country-name {
    font-size: 14px !important;
    line-height: 20px !important;
  }

}

/* booking form */
.booking.react-international-phone-input-container {
  height: 100%;
}

.booking.react-international-phone-input-container .react-international-phone-country-selector-button {
  border-radius: 0px !important;
  margin-right: 10px !important;
  padding-block: 0px 0px !important;
  box-shadow: none;
}

.booking.react-international-phone-input-container .react-international-phone-country-selector-button:focus {
  box-shadow: none;
}

.booking.react-international-phone-input-container .react-international-phone-input {
  border: none !important;
  background: transparent !important;
  font-size: 14px !important;
  padding-block: 0px 0px !important;
  color: #000 !important;
  height: 100% !important;
  margin-top: 0px !important;
  font-weight: 400;
}

.booking.react-international-phone-input-container .react-international-phone-input:focus {
  box-shadow: none;
}

.booking .react-international-phone-flag-emoji {
  width: 20px !important;
  height: 20px !important;
  box-sizing: border-box;
  object-fit: cover;
}

.booking .react-international-phone-country-selector-button__flag-emoji {
  margin: 0px 6px !important;
}

.booking .react-international-phone-country-selector-dropdown {
  pointer-events: auto !important;
  z-index: 50 !important;
  inset-block-start: 20px !important;
  box-shadow: none !important;
  background-color: white !important;
  width: 350px !important;
  max-height: 200px !important;
  border-radius: 6px !important;
  border: 2px solid #000 !important;
}

.booking .react-international-phone-country-selector-dropdown__list-item:hover {
  background-color: #FFFFFF4d !important;
  border: 1px solid #000 !important;
}

.booking .react-international-phone-country-selector-dropdown__list-item {
  height: 34px;
  width: auto !important;
  background-color: #e6e6e61a !important;
  box-shadow: rgba(102, 102, 102, 0.6) 0px 0px 0px 1px inset;
  color: #000 !important;
  padding: 0px 8px !important;
}

.booking .react-international-phone-country-selector-dropdown__list-item-country-name {
  color: #000 !important;
  font-size: 12px !important;
  line-height: 24px !important;
  font-family: 'Avenir Next';
}

.booking .react-international-phone-country-selector-dropdown__list-item-dial-code {
  color: #000 !important;
  font-size: 12px !important;
  line-height: 24px !important;
  margin-left: 8px;
}

@media (max-width : 768px) {
  .booking.react-international-phone-input-container .react-international-phone-input {
    font-size: 12px !important;
  }
}

@media (max-width : 645px) {
  .booking .react-international-phone-country-selector-dropdown {
    inset-block-start: 40px !important;
    inset-inline-start: -10px !important ;
    width: 250px !important;
    border: 1px solid #000 !important;
  }
  .booking .react-international-phone-country-selector-dropdown__list-item {
    height: 28px;
    padding: 0px 4px !important;
  }
  
  .booking .react-international-phone-country-selector-dropdown__list-item-country-name {
    font-size: 10px !important;
    line-height: 20px !important;
    font-family: 'Avenir Next';
  }
  
  .booking .react-international-phone-country-selector-dropdown__list-item-dial-code {
    color: #000 !important;
    font-size: 10px !important;
    line-height: 20px !important;
    margin-left: 4px;
  }

  .booking .react-international-phone-flag-emoji {
    width: 16px !important;
    height: 16px !important;
  }
}


/* affiliate-form and reseller-form Phone Number */

.reseller.react-international-phone-input-container {
  height: 50px;
}

.reseller.react-international-phone-input-container .react-international-phone-country-selector-button {
  border-radius: 0px !important;
  margin-right: 10px !important;
  border: none;
  opacity: 1;
  padding-block: 0px 0px !important;
  box-shadow: none;
  height: 100%;
  background: transparent !important;
}

.reseller.react-international-phone-input-container .react-international-phone-country-selector-button:focus {
  box-shadow: none;
}

.reseller.react-international-phone-input-container .react-international-phone-input {
  border: none !important;
  box-shadow: none;
  width: 100%;
  background: transparent !important;
  font-size: 14px !important;
  padding-block: 0px 0px !important;
  color: #000 !important;
  height: 100% !important;
  margin-top: 0px;
}

.reseller.react-international-phone-input-container .react-international-phone-input:focus {
  box-shadow: none;
}

.reseller .react-international-phone-flag-emoji {
  width: 20px !important;
  height: 20px !important;
  box-sizing: border-box;
  object-fit: cover;
}

.reseller .react-international-phone-country-selector-button__flag-emoji {
  margin: 0px 6px !important;
}

.reseller .react-international-phone-country-selector-dropdown {
  pointer-events: auto !important;
  z-index: 50 !important;
  list-style: none;
  padding-block: 10px;
  inset-block-start: 20px !important;
  box-shadow: none !important;
  background-color: white !important;
  width: 450px !important;
  max-height: 200px !important;
  overflow-y: scroll;
  border-radius: 6px !important;
  border: 2px solid #000 !important;
}

.reseller .react-international-phone-country-selector-dropdown__list-item:hover {
  background-color: #FFFFFF4d !important;
  border: 1px solid #000 !important;
}

.reseller .react-international-phone-country-selector-dropdown__list-item {
  height: 34px;
  max-width: 100%;
  width: auto !important;
  margin-inline: 16px;
  margin-block-end: 4px;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: #e6e6e61a !important;
  box-shadow: rgba(102, 102, 102, 0.6) 0px 0px 0px 1px inset;
  color: #000 !important;
  outline: 0px;
  padding-block: 4px;
  transition-duration: 0.1s;
  transition-property: color;
  transition-timing-function: ease-out;
  width: 100%;
  word-break: break-word;
  cursor: pointer;
  opacity: 1;
  padding: 0px 8px !important;
}

.reseller .react-international-phone-country-selector-dropdown__list-item-country-name {
  color: #000 !important;
  font-size: 14px !important;
  line-height: 28px !important;
  font-family: 'Avenir Next';
}

.reseller .react-international-phone-country-selector-dropdown__list-item-dial-code {
  color: #000 !important;
  font-size: 14px !important;
  line-height: 28px !important;
  margin-left: 10px;
}

@media (max-width : 768px) {
  .reseller.react-international-phone-input-container {
    height: 40px;
  }
}

.loader {
  border: 5px solid #c7c7c7;
  border-top: 5px solid #FDD01F;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin-top: 20px;
  margin-inline: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes upArrow {

  0%,
  100% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-10px);
  }

  75% {
    transform: translateY(10px);
  }
}

.up-arrow {
  animation: upArrow 4s infinite;
}

.feedback iframe {
  width: 640px !important;
}

@media (max-width : 645px) {
  .feedback iframe {
    height: 170px !important;
    width: 300px !important;
  }
}